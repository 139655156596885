/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'diabetes': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.73 2.29a1 1 0 011.41 1.41L13 6.79l.76.95 1.94 5a1.46 1.46 0 01-.34 1.55l-3.09 3.09a1.55 1.55 0 01-2.12 0l-4.6-4.6a1.37 1.37 0 01-.4-1l-.5-6.44h1.07a1 1 0 01.75.3l.14.17 1.05 3.48m0 12.71L2 16.36l2.12-2.12 5.66 5.66M19.5 4.5S17 7.26 17 9a2.5 2.5 0 105 0c0-1.74-2.5-4.5-2.5-4.5z"/>'
  }
})
